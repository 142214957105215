import raw from "./raw"

export default {
    Template: {
        /**
         * Liest eine Picklist via ID aus
         * 
         * @param {number} id
         * @returns {Promise<object>}
         */
        async ByID(id) {
            return raw.get('/v1/picklist/template/' + id);
        },

        /**
         * Löscht einen Eintrag aus der Liste
         * @param {number} templateId
         * @param {number} entryId
         * @returns {Promise<object>}
         */
        async DeleteEntry(templateId, entryId) {
            return raw.delete('/v1/picklist/template/' + templateId + '/' + entryId);
        },

        /**
         * Erstellt eine neue Pickliste auf Basis einer Vorlage
         * @param {object} properties 
         * @param {string} properties.display_name
         * @param {number} properties.customer_id
         * @param {string} properties.comment
         * @returns {Promise<object>}
         */
        async Create(properties) {
            return raw.put('/v1/picklist/template', {
                display_name: properties.display_name,
                customer_id: properties.customer_id*1,
                comment: properties.comment || 0,
            });
        },

        /**
         * Fügt einen EIntrag zu einer Liste hinzu
         * @param {number} templateId ID der Liste
         * @param {object} properties
         * @param {object} properties.count Anzahl der zu erstellenden Einträge
         * @param {object} properties.material_id Material-ID des neuen Eintrages; kann auf NULL gesetzt werden
         * @param {object} properties.farbanteil_min Eigenschaft farbanteil des neuen Eintrages
         * @param {object} properties.farbanteil_max Eigenschaft farbanteil des neuen Eintrages
         * @param {object} properties.sg_min Eigenschaft sg des neuen Eintrages
         * @param {object} properties.sg_max Eigenschaft sg des neuen Eintrages
         * @param {object} properties.ne_min Eigenschaft ne des neuen Eintrages
         * @param {object} properties.ne_max Eigenschaft ne des neuen Eintrages
         * @param {object} properties.etikettenpet_min Eigenschaft etikettenpet des neuen Eintrages
         * @param {object} properties.etikettenpet_max Eigenschaft etikettenpet des neuen Eintrages
         * @param {object} properties.flakes_min Eigenschaft flakes des neuen Eintrages
         * @param {object} properties.flakes_max Eigenschaft flakes des neuen Eintrages
         * @param {object} properties.verunreinigung_min Eigenschaft verunreinigung des neuen Eintrages
         * @param {object} properties.verunreinigung_max Eigenschaft verunreinigung des neuen Eintrages
         * @param {object} properties.etiketten_min Eigenschaft etiketten des neuen Eintrages
         * @param {object} properties.etiketten_max Eigenschaft etiketten des neuen Eintrages
         * @param {object} properties.dunkel_min Eigenschaft dunkel des neuen Eintrages
         * @param {object} properties.dunkel_max Eigenschaft dunkel des neuen Eintrages
         * @param {object} properties.opak_min Eigenschaft opak des neuen Eintrages
         * @param {object} properties.opak_max Eigenschaft opak des neuen Eintrages
         * @param {object} properties.fe_min Eigenschaft fe des neuen Eintrages
         * @param {object} properties.fe_max Eigenschaft fe des neuen Eintrages
         * @param {object} properties.po_min Eigenschaft po des neuen Eintrages
         * @param {object} properties.po_max Eigenschaft po des neuen Eintrages
         * @param {object} properties.pe_min Eigenschaft pe des neuen Eintrages
         * @param {object} properties.pe_max Eigenschaft pe des neuen Eintrages
         * @param {object} properties.pp_min Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pp_max Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pc_min Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pc_max Eigenschaft pp des neuen Eintrages
         * @param {object} properties.tpe_min Eigenschaft pp des neuen Eintrages
         * @param {object} properties.tpe_max Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pa_min Eigenschaft pa des neuen Eintrages
         * @param {object} properties.pa_max Eigenschaft pa des neuen Eintrages
         * @param {object} properties.sonstiges_min Eigenschaft sonstiges des neuen Eintrages
         * @param {object} properties.sonstiges_max Eigenschaft sonstiges des neuen Eintrages
         * @param {object} properties.lightblue_min Eigenschaft lightblue des neuen Eintrages
         * @param {object} properties.lightblue_max Eigenschaft lightblue des neuen Eintrages
         * @param {object} properties.storagelocation_id Erforderliche Storage-Location des Eintrages; kann NULL gesetzt werden
         */
        async AddEntry(templateId, properties) {
            return raw.post('/v1/picklist/template/' + templateId, {
                count: (properties.count != '' ? properties.count * 1 : 1),
                material_id: (properties.material_id != '' ? properties.material_id * 1 : null),
                farbanteil_min: (properties.farbanteil_min != '' ? properties.farbanteil_min * 1 : null),
                farbanteil_max: (properties.farbanteil_max != '' ? properties.farbanteil_max * 1 : null),
                sg_min: (properties.sg_min != '' ? properties.sg_min * 1 : null),
                sg_max: (properties.sg_max != '' ? properties.sg_max * 1 : null),
                ne_min: (properties.ne_min != '' ? properties.ne_min * 1 : null),
                ne_max: (properties.ne_max != '' ? properties.ne_max * 1 : null),
                etikettenpet_min: (properties.etikettenpet_min != '' ? properties.etikettenpet_min * 1 : null),
                etikettenpet_max: (properties.etikettenpet_max != '' ? properties.etikettenpet_max * 1 : null),
                flakes_min: (properties.flakes_min != '' ? properties.flakes_min * 1 : null),
                flakes_max: (properties.flakes_max != '' ? properties.flakes_max * 1 : null),
                verunreinigung_min: (properties.verunreinigung_min != '' ? properties.verunreinigung_min * 1 : null),
                verunreinigung_max: (properties.verunreinigung_max != '' ? properties.verunreinigung_max * 1 : null),
                etiketten_min: (properties.etiketten_min != '' ? properties.etiketten_min * 1 : null),
                etiketten_max: (properties.etiketten_max != '' ? properties.etiketten_max * 1 : null),
                dunkel_min: (properties.dunkel_min != '' ? properties.dunkel_min * 1 : null),
                dunkel_max: (properties.dunkel_max != '' ? properties.dunkel_max * 1 : null),
                opak_min: (properties.opak_min != '' ? properties.opak_min * 1 : null),
                opak_max: (properties.opak_max != '' ? properties.opak_max * 1 : null),
                fe_min: (properties.fe_min != '' ? properties.fe_min * 1 : null),
                fe_max: (properties.fe_max != '' ? properties.fe_max * 1 : null),
                po_min: (properties.po_min != '' ? properties.po_min * 1 : null),
                po_max: (properties.po_max != '' ? properties.po_max * 1 : null),
                pe_min: (properties.pe_min != '' ? properties.pe_min * 1 : null),
                pe_max: (properties.pe_max != '' ? properties.pe_max * 1 : null),
                pp_min: (properties.pp_min != '' ? properties.pp_min * 1 : null),
                pp_max: (properties.pp_max != '' ? properties.pp_max * 1 : null),
                pc_min: (properties.pc_min != '' ? properties.pc_min * 1 : null),
                pc_max: (properties.pc_max != '' ? properties.pc_max * 1 : null),
                tpe_min: (properties.tpe_min != '' ? properties.tpe_min * 1 : null),
                tpe_max: (properties.tpe_max != '' ? properties.tpe_max * 1 : null),
                pa_min: (properties.pa_min != '' ? properties.pa_min * 1 : null),
                pa_max: (properties.pa_max != '' ? properties.pa_max * 1 : null),
                sonstiges_min: (properties.sonstiges_min != '' ? properties.sonstiges_min * 1 : null),
                sonstiges_max: (properties.sonstiges_max != '' ? properties.sonstiges_max * 1 : null),
                lightblue_min: (properties.lightblue_min != '' ? properties.lightblue_min * 1 : null),
                lightblue_max: (properties.lightblue_max != '' ? properties.lightblue_max * 1 : null),
                storagelocation_id: (properties.storagelocation_id != '' ? properties.storagelocation_id * 1 : null),
            });
        },

        async GetCompanyOverview() {
            return raw.get('/v1/picklist/template');
        },
    },

    
    Order: {
        /**
         * Liest eine Picklist via ID aus
         * 
         * @param {number} id
         * @returns {Promise<object>}
         */
        async ByID(id) {
            return raw.get('/v1/picklist/order/' + id);
        },

        /**
         * Liest eine Picklisten via open-State aus
         * 
         * @returns {Promise<object>}
         */
        async ByOpenState() {
            return raw.get('/v1/picklist/order/open');
        },

        /**
         * Liest eine Picklisten via NotFullyLoaded-State aus
         * 
         * @returns {Promise<object>}
         */
        async ByNotFullyLoadedState() {
            return raw.get('/v1/picklist/order/notloaded');
        },

        /**
         * Nach unassigned state
         * @returns {Promise<object>}
         */
        async ByUnassignedState() {
            return raw.get('/v1/picklist/order');
        },

        /**
         * Löscht einen Eintrag aus der Liste
         * @param {number} templateId
         * @param {number} entryId
         * @returns {Promise<object>}
         */
        async DeleteEntry(templateId, entryId) {
            return raw.delete('/v1/picklist/order/' + templateId + '/' + entryId);
        },

        /**
         * Erstellt eine neue Pickliste auf Basis einer Vorlage
         * @param {object} properties 
         * @param {string} properties.display_name
         * @param {number} properties.customer_id
         * @param {number} properties.order_id
         * @param {string} properties.comment
         * @returns {Promise<object>}
         */
        async Create(properties) {
            return raw.put('/v1/picklist/order/', {
                display_name: properties.display_name,
                customer_id: properties.customer_id*1,
                order_id: properties.order_id || 0,
                comment: properties.comment || 0,
            });
        },

        /**
         * Erstellt eine neue Pickliste auf Basis einer Vorlage
         * @param {object} properties
         * @param {number} properties.template_id 
         * @param {number} properties.order_id
         * @returns {Promise<object>}
         */
        async CreateFromTemplate(properties) {
            return raw.put('/v1/picklist/order/', {
                template_id: properties.template_id,
                order_id: properties.order_id
            });
        },

        /**
         * Fügt einen EIntrag zu einer Liste hinzu
         * @param {number} templateId ID der Liste
         * @param {object} properties
         * @param {object} properties.count Anzahl der zu erstellenden Einträge
         * @param {object} properties.material_id Material-ID des neuen Eintrages; kann auf NULL gesetzt werden
         * @param {object} properties.farbanteil_min Eigenschaft farbanteil des neuen Eintrages
         * @param {object} properties.farbanteil_max Eigenschaft farbanteil des neuen Eintrages
         * @param {object} properties.sg_min Eigenschaft sg des neuen Eintrages
         * @param {object} properties.sg_max Eigenschaft sg des neuen Eintrages
         * @param {object} properties.ne_min Eigenschaft ne des neuen Eintrages
         * @param {object} properties.ne_max Eigenschaft ne des neuen Eintrages
         * @param {object} properties.etikettenpet_min Eigenschaft etikettenpet des neuen Eintrages
         * @param {object} properties.etikettenpet_max Eigenschaft etikettenpet des neuen Eintrages
         * @param {object} properties.flakes_min Eigenschaft flakes des neuen Eintrages
         * @param {object} properties.flakes_max Eigenschaft flakes des neuen Eintrages
         * @param {object} properties.verunreinigung_min Eigenschaft verunreinigung des neuen Eintrages
         * @param {object} properties.verunreinigung_max Eigenschaft verunreinigung des neuen Eintrages
         * @param {object} properties.etiketten_min Eigenschaft etiketten des neuen Eintrages
         * @param {object} properties.etiketten_max Eigenschaft etiketten des neuen Eintrages
         * @param {object} properties.dunkel_min Eigenschaft dunkel des neuen Eintrages
         * @param {object} properties.dunkel_max Eigenschaft dunkel des neuen Eintrages
         * @param {object} properties.opak_min Eigenschaft opak des neuen Eintrages
         * @param {object} properties.opak_max Eigenschaft opak des neuen Eintrages
         * @param {object} properties.fe_min Eigenschaft fe des neuen Eintrages
         * @param {object} properties.fe_max Eigenschaft fe des neuen Eintrages
         * @param {object} properties.po_min Eigenschaft po des neuen Eintrages
         * @param {object} properties.po_max Eigenschaft po des neuen Eintrages
         * @param {object} properties.pe_min Eigenschaft pe des neuen Eintrages
         * @param {object} properties.pe_max Eigenschaft pe des neuen Eintrages
         * @param {object} properties.pp_min Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pp_max Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pc_min Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pc_max Eigenschaft pp des neuen Eintrages
         * @param {object} properties.tpe_min Eigenschaft pp des neuen Eintrages
         * @param {object} properties.tpe_max Eigenschaft pp des neuen Eintrages
         * @param {object} properties.pa_min Eigenschaft pa des neuen Eintrages
         * @param {object} properties.pa_max Eigenschaft pa des neuen Eintrages
         * @param {object} properties.sonstiges_min Eigenschaft sonstiges des neuen Eintrages
         * @param {object} properties.sonstiges_max Eigenschaft sonstiges des neuen Eintrages
         * @param {object} properties.lightblue_min Eigenschaft lightblue des neuen Eintrages
         * @param {object} properties.lightblue_max Eigenschaft lightblue des neuen Eintrages
         * @param {object} properties.storagelocation_id Erforderliche Storage-Location des Eintrages; kann NULL gesetzt werden
         */
        async AddEntry(templateId, properties) {
            return raw.post('/v1/picklist/order/' + templateId, {
                count: (properties.count != '' ? properties.count * 1 : 1),
                material_id: (properties.material_id != '' ? properties.material_id * 1 : null),
                farbanteil_min: (properties.farbanteil_min != '' ? properties.farbanteil_min * 1 : null),
                farbanteil_max: (properties.farbanteil_max != '' ? properties.farbanteil_max * 1 : null),
                sg_min: (properties.sg_min != '' ? properties.sg_min * 1 : null),
                sg_max: (properties.sg_max != '' ? properties.sg_max * 1 : null),
                ne_min: (properties.ne_min != '' ? properties.ne_min * 1 : null),
                ne_max: (properties.ne_max != '' ? properties.ne_max * 1 : null),
                etikettenpet_min: (properties.etikettenpet_min != '' ? properties.etikettenpet_min * 1 : null),
                etikettenpet_max: (properties.etikettenpet_max != '' ? properties.etikettenpet_max * 1 : null),
                flakes_min: (properties.flakes_min != '' ? properties.flakes_min * 1 : null),
                flakes_max: (properties.flakes_max != '' ? properties.flakes_max * 1 : null),
                verunreinigung_min: (properties.verunreinigung_min != '' ? properties.verunreinigung_min * 1 : null),
                verunreinigung_max: (properties.verunreinigung_max != '' ? properties.verunreinigung_max * 1 : null),
                etiketten_min: (properties.etiketten_min != '' ? properties.etiketten_min * 1 : null),
                etiketten_max: (properties.etiketten_max != '' ? properties.etiketten_max * 1 : null),
                dunkel_min: (properties.dunkel_min != '' ? properties.dunkel_min * 1 : null),
                dunkel_max: (properties.dunkel_max != '' ? properties.dunkel_max * 1 : null),
                opak_min: (properties.opak_min != '' ? properties.opak_min * 1 : null),
                opak_max: (properties.opak_max != '' ? properties.opak_max * 1 : null),
                fe_min: (properties.fe_min != '' ? properties.fe_min * 1 : null),
                fe_max: (properties.fe_max != '' ? properties.fe_max * 1 : null),
                po_min: (properties.po_min != '' ? properties.po_min * 1 : null),
                po_max: (properties.po_max != '' ? properties.po_max * 1 : null),
                pe_min: (properties.pe_min != '' ? properties.pe_min * 1 : null),
                pe_max: (properties.pe_max != '' ? properties.pe_max * 1 : null),
                pp_min: (properties.pp_min != '' ? properties.pp_min * 1 : null),
                pp_max: (properties.pp_max != '' ? properties.pp_max * 1 : null),
                pc_min: (properties.pc_min != '' ? properties.pc_min * 1 : null),
                pc_max: (properties.pc_max != '' ? properties.pc_max * 1 : null),
                tpe_min: (properties.tpe_min != '' ? properties.tpe_min * 1 : null),
                tpe_max: (properties.tpe_max != '' ? properties.tpe_max * 1 : null),
                pa_min: (properties.pa_min != '' ? properties.pa_min * 1 : null),
                pa_max: (properties.pa_max != '' ? properties.pa_max * 1 : null),
                sonstiges_min: (properties.sonstiges_min != '' ? properties.sonstiges_min * 1 : null),
                sonstiges_max: (properties.sonstiges_max != '' ? properties.sonstiges_max * 1 : null),
                lightblue_min: (properties.lightblue_min != '' ? properties.lightblue_min * 1 : null),
                lightblue_max: (properties.lightblue_max != '' ? properties.lightblue_max * 1 : null),
                storagelocation_id: (properties.storagelocation_id != '' ? properties.storagelocation_id * 1 : null),
            });
        },

        /**
         * Fügt einen Bigbag zu einem Eintrag hinzu
         * @param {number} picklistId
         * @param {number} entryId
         * @param {number} bigbagId
         * @returns {Promise<object>}
         */
        attachBigbagToEntry(picklistId, entryId, bigbagId) {
            return raw.post('/v1/picklist/order/' + picklistId +'/'+ entryId, {
                bigbag_id: bigbagId
            });
        },
    },
}