// Vue
import { createApp } from 'vue'
import App from './App.vue'

import { Transition } from 'vue';

// Font Awesome
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUserSecret, faBars, faHouse, faSuitcase, faCube, faWarehouse,
    faSpinner, faArrowUp, faArrowDown, faTriangleExclamation,
    faArrowRightFromBracket, faArrowRightToBracket, faArrowsDownToLine,
    faArrowsUpToLine, faPencil, faXmark, faTrashCan, faTrashCanArrowUp,
    faPlus, faUser, faCopy, faCheck, faDownload, faMoneyCheck, faList,
    faWeightScale, faPrint, faQrcode, faBug, faPlusMinus, faCalendar,
    faCalendarAlt, faTruck, faKey, faShoppingCart, faRotate, faBottleWater,
    faMapPin, faCogs, faBook, faComment, faRecycle, faSearch, faUserShield,
    faEye, faChartSimple, faCalculator, faHand, faArrowLeft, faArrowRight,
    faCodeCompare, faStopwatch, faMinus, faPieChart, faSave, faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

library.add([
    faUserSecret,
    faBars,
    faHouse,        // Home
    faSuitcase,     // Big Bags
    faCube,         // Lagerorte
    faWarehouse,    // Big Bag Lager
    faSpinner,      // Ladeanimation
    faArrowUp,      // Aufsteigend sortiert
    faArrowDown,    // Absteigend sortiert
    faTriangleExclamation,  // Fehler
    faArrowRightToBracket,      // Annahme
    faArrowRightFromBracket,    // Aufgabe
    faArrowsDownToLine,         // Hierher umladen
    faArrowsUpToLine,           // Von hier umladen
    faPencil,       // Bearbeitem
    faXmark,        // Deaktiviert
    faTrashCan, faTrashCanArrowUp, // Löschen und wiederherstellen 
    faPlus,
    faMinus,
    faUser,         // Kunden
    faCopy,
    faCheck,
    faDownload,
    faMoneyCheck,
    faList,         // Pickliste
    faWeightScale,
    faPrint,
    faQrcode,
    faBug,
    faPlusMinus,
    faCalendar,
    faCalendarAlt,
    faTruck,
    faKey,
    faShoppingCart,
    faRotate,
    faBottleWater,
    faMapPin,
    faCogs,
    faBook,
    faComment,
    faRecycle,
    faSearch,
    faUserShield,
    faEye,
    faChartSimple,
    faCalculator,
    faHand,
    faArrowLeft,
    faArrowRight,
    faCodeCompare,
    faStopwatch,
    faPieChart,
    faSave,
    faDollarSign,
]);

// Pinia
import { createPinia } from 'pinia'
const pinia = createPinia()

// Login Mechanismus
import AuthAPI from '@/lib/api/auth.js';
import UserAPI from '@/lib/api/user.js';
import { useCurrentUserStore } from '@/stores/currentUser'
import { useLayoutStore } from '@/stores/layout';
import { useServersideOptionsStore } from '@/stores/serversideOptions';
import { useWeightTaraStore } from '@/stores/weightTara.js';

// Router
import router from './router'

// Datepicker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// toastification
import Toast from "vue-toastification";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

// Vue 3 Treeview
import "vue3-treeview/dist/style.css";

// Vue Multiselect
import 'vue-multiselect/dist/vue-multiselect.css';

// Vue Debounec
import { vue3Debounce } from 'vue-debounce'

// Permission
import PermissionComponent from '@/components/common/Permission.vue';
import ShortLayoutComponent from '@/components/common/ShortLayout.vue';

import TableWithStickyHeaderComponent from '@/components/common/TableWithStickyHeader.vue';

(async () => {
    // Setup vue
    const app = createApp(App);
    app.use(pinia); // Pinia
    app.use(Toast); // toastification
    app.component('font-awesome-icon', FontAwesomeIcon); // Font Awesome
    app.component('font-awesome-layers', FontAwesomeLayers); // Font Awesome
    app.component('VueDatePicker', VueDatePicker); // Datepicker
    app.component('PermissionComponent', PermissionComponent);
    app.component('ShortLayoutComponent', ShortLayoutComponent);
    app.component('TableWithStickyHeader', TableWithStickyHeaderComponent);
    app.component('transition', Transition); // Bugfix für https://github.com/drummerroma/vue3-treeselect
    app.directive('debounce', vue3Debounce({ lock: true })); // Debounce

    const currentUser = useCurrentUserStore();
    const toast = useToast();
    if (currentUser.currentSessionToken != null) {
        var user = null;
        try {
            // @login
            user = await AuthAPI.Validate();
            currentUser.currentUser = user.data;

            const currentSSOStore = useServersideOptionsStore();
            currentSSOStore.setOptions((await UserAPI.LoadProperties()).data);

            const weightTaraStore = useWeightTaraStore();
            await weightTaraStore.loadTaras();
        } catch (e) {
            currentUser.currentSessionToken = null;
            toast.error("Fehler bei Wiederherstellung der Sitzung.\nBitte erneut anmelden.", {
                position: "bottom-center",
                timeout: 2500,
                closeOnClick: true,
            });
        }
    }

    app.use(router); // Router
    app.mount('#app'); // Mount

    const layoutStore = useLayoutStore();

    document.onkeydown = function (e) {
        if (e.key === "q" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            currentUser.debugEnabled = !currentUser.debugEnabled;
        }
        if (e.key === "y" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            layoutStore.isSearchVisible = !layoutStore.isSearchVisible;
        }
    };

})();

// CSS
import './assets/css/main.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "vue-search-select/dist/VueSearchSelect.css"