<script setup>
import { ModelSelect } from "vue-search-select"
import { ref, computed, onMounted, watch } from 'vue';
import { formatWeight, formatDate } from '@/lib/util/formatter.js';
import GuestAPI from '@/lib/api/guest.js';

const AvailableMaterials = ref(null);
const AvailableMaterialsSelect = computed(() => {
    if (AvailableMaterials.value == null) return [];

    var ret = [];
    for (var i = 0; i < AvailableMaterials.value.length; i++) {
        ret.push({
            value: AvailableMaterials.value[i].id,
            text: AvailableMaterials.value[i].name,
        });
    }
    return ret;
});

const AvailableStoragelocations = ref(null);
const AvailableStoragelocationsSelect = computed(() => {
    if (AvailableStoragelocations.value == null) return [];

    var ret = [];
    for (var i = 0; i < AvailableStoragelocations.value.length; i++) {
        ret.push({
            value: AvailableStoragelocations.value[i].id,
            text: AvailableStoragelocations.value[i].name,
        });
    }
    return ret;
});


const toSubmitData = ref({
    weight: null,
    weight_date: null,
    material_to: 0,
    //storagelocation_id: 0,
});

const displayErrorMessage = ref(null);
watch(displayErrorMessage, (to) => {
    if (to == null) return;
    setTimeout(() => displayErrorMessage.value = null, 5000);
});
const errorMessage = computed(() => {
    if (displayErrorMessage.value != null) return displayErrorMessage.value;
    if (toSubmitData.value.weight <= 0) return 'Das Gewicht muss mindestens 1 Kilogramm betragen.';
    if (toSubmitData.value.weight_date == null) return 'Ungültiges Datum';
    if (toSubmitData.value.material_to == 0) return 'Das Material muss angegeben werden.';
    //if (toSubmitData.value.storagelocation_id == 0) return 'Das Verkaufslager muss angegeben werden.';
    return null;
});

const isLoading = ref(false);

const add = async () => {
    if (isLoading.value) return;
    isLoading.value = true;
    try {
        const addedBigbag = await GuestAPI.CreateBigbag(toSubmitData.value);
        window.location.href = GuestAPI.GetBigbagPDFURL(addedBigbag.data.id);
        //window.open(GuestAPI.GetBigbagPDFURL(addedBigbag.data.id));
        //window.setTimeout(function () { window.close(); }, 20)
    } catch (e) {
        displayErrorMessage.value = e.message;
    }
    isLoading.value = false;
};

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    //if( && urlParams.get('TS') && urlParams.get('target') && urlParams.get('target') == 'x') 

    toSubmitData.value.weight = urlParams.get('WT') * 1;
    toSubmitData.value.weight_date = new Date(urlParams.get('TS').replace('_', 'T'));
    (async () => {
        try {
            AvailableMaterials.value = (await GuestAPI.GetMaterials()).data;
            AvailableStoragelocations.value = (await GuestAPI.GetStoragelocations()).data;
        } catch (e) {
        }
    })();
});
</script>
<template>
    <div class="wrapper">
        <div class="container mt-4 pt-4">
            <div class="card mt-4">
                <div class="card-body">
                    <strong>Bigbag einwiegen</strong>
                    <hr />
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Gewicht</td>
                                <th class="data">{{ formatWeight(toSubmitData.weight) }}</th>
                            </tr>
                            <tr>
                                <td>Datum</td>
                                <th class="data">{{ formatDate(toSubmitData.weight_date) }}</th>
                            </tr>
                        </tbody>
                    </table>

                    <label class="d-block mt-4">Material</label>
                    <input class="disabled form-control" disabled v-if="AvailableMaterials == null" placeholder="Lade..." />
                    <ModelSelect v-else :options="AvailableMaterialsSelect" v-model="toSubmitData.material_to"
                        placeholder="Bitte auswählen" class="input-xl" />
                    <!--
                        <label class="d-block mt-4">Verkaufslager</label>
                        <input class="disabled form-control" disabled v-if="AvailableStoragelocations == null"
                            placeholder="Lade..." />
                        <ModelSelect v-else :options="AvailableStoragelocationsSelect" v-model="toSubmitData.storagelocation_id"
                            placeholder="Bitte auswählen" />
                    -->
                    <!-- Button unten -->
                    <button class="btn btn-danger w-100 mt-4 pt-3 pb-3" v-if="errorMessage != null">
                        {{ errorMessage }}
                    </button>
                    <button class="btn btn-success w-100 mt-4 pt-3 pb-3" v-else-if="!isLoading" @click="add()">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                        Hinzufügen
                    </button>
                    <button class="btn btn-success w-100 mt-4 pt-3 pb-3" v-else>
                        <font-awesome-icon icon="fa-solid fa-spinner" spin-pulse class="me-2" />
                        Bitte warten...
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    align-self: center;
}

.card  * {
    font-size: 120% !important;
}

.card :deep(.ui .text),
.card :deep(.ui input.search),
.card :deep(.ui .item) {
    font-size: 120% !important;
}

label {
    font-weight: bold;
}

.wrapper {
    display: block;
    width: 100dvw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    background-image: url('/src/assets/images/login-bg-tile.png');
}
</style>