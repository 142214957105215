import { ref } from 'vue';
import { defineStore } from 'pinia';
import WeightAPI from '@/lib/api/weight.js';
import { useToast } from "vue-toastification";

export const useWeightTaraStore = defineStore('weightStore', () => {
    const currentWeightMode = ref(null);
    const toast = useToast();

    const taras = ref(null);
    var counterIndex = 0; // Race conditions verhindern

    async function loadTaras() {
        try {
            counterIndex++;
            const start = counterIndex * 1;
            var data = (await WeightAPI.GetCurrentTara()).data;
            if (start !== counterIndex * 1) return;
            taras.value = data;
        } catch (e) {
            toast.error("Fehler beim Abrufen der Tara-Informationen:\n" + e, {
                position: "bottom-center",
                timeout: 2500,
                closeOnClick: true,
            });
        }
    }

    async function setTara(scale, to) {
        try {
            counterIndex++;
            const start = counterIndex * 1;
            var data = (await WeightAPI.SetTara(scale, to)).data;
            if (start !== counterIndex * 1) return;
            taras.value = data;
            toast.success("Taara für Waage " + scale + " aktualisiert", {
                position: "bottom-center",
                timeout: 2500,
                closeOnClick: true,
            });
        } catch (e) {
            toast.error("Fehler beim Aktualsieren von Waage " + scale + ":\n" + e, {
                position: "bottom-center",
                timeout: 2500,
                closeOnClick: true,
            });
        }
    }

    const startTara = () => {
        currentWeightMode.value = 'tara';
    };

    const startWeight = () => {
        currentWeightMode.value = 'weight';
    };

    const startTaraOrWeight = () => {
        currentWeightMode.value = 'both';
    };

    const stop = () => {
        currentWeightMode.value = null;
    };

    return {
        taras,
        loadTaras,
        setTara,
        currentWeightMode,
        startTara,
        startWeight,
        startTaraOrWeight,
        stop,
    }
});