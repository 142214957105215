import raw from "./raw"

export default {
    /**
     * Liest alle Materialien via API aus
     * 
     * @returns {Promise<object>}
     */
    async GetMaterials() {
        return raw.get('/v1/guest/waage/get-materials');
    },
    
    /**
     * Liest alle Storagelocations via API aus
     * 
     * @returns {Promise<object>}
     */
    async GetStoragelocations() {
        return raw.get('/v1/guest/waage/get-storagelocation');
    },
    
    /**
     * Erstellt einen neuen Bigbag
     * 
     * @param {object} properties
     * @param {number} properties.weight
     * @param {number} properties.weight_date
     * @param {number} properties.material_to
     * @param {number?} properties.storagelocation_id
     * @returns {Promise<object>}
     */
    async CreateBigbag(properties) {
        return raw.post('/v1/guest/waage', properties);
    },
    
    /**
     * Gibt die URL zu der PDF eines Bigbags zurück
     * @param {number} bigbag_id
     * @returns {string}
     */
    GetBigbagPDFURL(bigbag_id) {
        return raw.apiBaseURL +"/v1/guest/ws/"+ bigbag_id;
    },
}